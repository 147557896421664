@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Poppins:wght@100;200;400;500;600;700&family=Sacramento&family=Satisfy&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
/* variable for color */
:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: #4db5ff66;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;

  /* background: var(--color-bg); */
  background-image: url("./assests/bg.jpg");
  color: #fff;
  line-height: 1.7;
}

.btn {
  width: max-content;
  display: inline-block;
  color: #4db5ff;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #4db5ff;
  transition: all 400ms ease;
}
.btn:hover {
  background: #4db5ff;
  color: #1f1f38;
  border-color: transparent;
}
.btn2 {
  width: max-content;
  background: #4db5ff;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #4db5ff;
  transition: all 400ms ease;
  color: #1f1f38;
}
.btn2:hover {
  background: #1f1f38;
  color: #4db5ff;
}
