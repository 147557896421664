.Projects {
  width: 100%;
  height: 100vh;
  /* margin-left: 1em; */
}
.Projects {
  align-items: center;
}
.Projects p {
  font-size: 30px;
}
.ProjectContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.Projects .ProjectBox {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.Projects .ProjectBox:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.Projects .ProjectBox .ProjectImage {
  border-radius: 1.5rem;
  overflow: hidden;
  width: 100%;
}
.ProjectBoxCTA {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.ProjectContent {
  height: 40%;
}
