.Header {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.Header-Content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10em;
  gap: 5em;
}

.CV {
  display: flex;
  gap: 1em;
  justify-content: center;
}
.Header .Image {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* align-content: inherit; */
}
.Header .Image .Profile-Image {
  width: max-content;
  background: linear-gradient(#4db5ff, transparent);
  border-radius: 0em 00em 0 0;
}
.animation {
  position: relative;
}
.Header .Profile-Image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8em 0 0 8em;
}

/* ********Social_CSS******** */

.Socials {
  display: flex;
  height: 100vh;
  align-items: center;
  position: absolute;
}
.Social-Links {
  width: max-content;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background: rgba(72, 72, 72, 0.5);
  padding: 2em 0.3em;
  border-radius: 1em;
  backdrop-filter: blur(5px);
}
.Social-Links a {
  color: #fff;
  cursor: pointer;
}
.Social-Links a:hover {
  color: #4db5ff;
}

/* ***********FOR MEDIUM DEVICES LIKE TABLET*********** */
@media screen and (max-width: 1024px) {
  .Header-Content {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
    gap: 5em;
    display: flex;
    flex-direction: column;
  }
  .Header .Image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Header .Image .Profile-Image {
    width: max-content;
    background: linear-gradient(#4db5ff, transparent);
    border-radius: 10em 10em 8em 8em;
  }
}

/* ***********FOR SMALL DEVICES LIKE MOBILE*********** */

@media screen and (max-width: 600px) {
  .Header-Content {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
    gap: 5em;
    display: flex;
    flex-direction: column;
  }
  .Header .Image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Header .Image .Profile-Image {
    width: max-content;
    background: linear-gradient(#4db5ff, transparent);
    border-radius: 10em 10em 8em 8em;
  }
}
