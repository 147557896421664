.Navbar {
  width: 80%;
  height: 10vh;
  left: 10%;
  top: 2em;
  display: flex;
  padding: 1em 2em;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  border-radius: 100px;
  backdrop-filter: blur(15px);
  justify-content: space-between;
}
.Navbar .Logo {
  margin-left: 4em;
  font-family: "Cookie", cursive;
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 0.3;
}
.Navbar .List {
  display: flex;
  gap: 3em;
  float: right;
  cursor: pointer;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.Navbar .List li a {
  color: #fff;
}
.Navbar .List li a:hover {
  color: #4db5ff;
  transition: 0.4s;
}
.Navbar .List li a::after {
  content: "";
  width: 0%;
  height: 1.5px;
  background: #4db5ff;
  display: block;
  margin: auto;
  transition: 0.3s;
}
.Navbar .List li a:hover::after {
  width: 100%;
}

li.active {
  color: #4db5ff;
}
.Navbar .Menu .Open-Menu {
  display: none;
}
/* ***********FOR MEDIUM DEVICES LIKE TABLET*********** */
@media screen and (max-width: 1024px) {
  .Navbar .Menu .Open-Menu {
    align-items: center;
    height: max-content;
    cursor: pointer;
    display: block;
  }
  .Navbar .List {
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 35%;
    width: 50vw;
    height: max-content;
    padding: 3em 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    transition: 0.2s ease-in;
    font-size: 0px;
  }
  .Navbar .menu_list {
    font-size: 17.5px;
  }
}
/* ***********FOR SMALL DEVICES LIKE MOBILE*********** */

@media screen and (max-width: 600px) {
  .Navbar .Menu .Open-Menu {
    align-items: center;
    height: max-content;
    cursor: pointer;
    display: block;
  }
  .Navbar .List {
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 35%;
    width: 50vw;
    height: max-content;
    padding: 3em 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    transition: 0.2s ease-in;
    opacity: 0%;
  }
  .Navbar .menu_list {
    opacity: 100%;
  }
}
@media screen and (max-width: 380px) {
  .Navbar .Logo {
    font-family: "Cookie", cursive;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
