@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.about {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.h1-text {
  font-size: 50px;
  font-weight: 400;
  width: max-content;
  line-height: 1;
  margin-left: 3em;
  font-family: "Montserrat", serif;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(255, 255, 255); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 255, 255);
  }
}
.h1-text a {
  color: #4db5ff;
}
.p-text {
  border-right: 5px solid rgb(255, 255, 255);
  font-size: 30px;
  width: fit-content;
  text-align: start;
  text-decoration: wavy;
  line-height: 1;
  font-family: "Montserrat", serif;
  margin: 0 2em 0 2em;
}

/* ***********FOR MEDIUM DEVICES LIKE TABLET*********** */
@media screen and (max-width: 1024px) {
  .h1-text {
    font-size: 30px;
    width: max-content;
    text-align: start;
    line-height: 1;
    margin-left: 3em;
    font-family: "Montserrat", serif;
  }
  .h1-text a {
    color: #4db5ff;
  }
  .p-text {
    width: fit-content;
    text-align: start;

    line-height: 1;
  }
  .about {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

/* ***********FOR SMALL DEVICES LIKE MOBILE*********** */
@media screen and (max-width: 600px) {
  .h1-text {
    font-size: 30px;
    width: max-content;
    text-align: start;
    line-height: 1;
    margin-left: 3em;
    font-family: "Montserrat", serif;
  }
  .h1-text a {
    color: #4db5ff;
  }
  .p-text {
    width: fit-content;
    line-height: 1;
  }
  .about {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
